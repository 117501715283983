@import '../../theme/colours';

.input {
  width: 100%;
  height: 44px;
  border: 1px solid $color-NGLight2;
  margin: 0;
  min-width: 0;
  border-radius: 6px;
  background: white;
  box-sizing: content-box;
  letter-spacing: inherit;
  font-size: 16px;
  color: $color-NGDark1;
  -webkit-tap-highlight-color: transparent;
  text-indent: 10px;
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.05);
  }

  &:focus {
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.05);
    outline: none;
    border-color: $color-NGLight1;
  }

  &.focusedContainer {
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.05);
    border-color: $color-NGLight1;
  }
}

.datePicker {
  width: 100%;
  height: 44px;
}

.filterContainer {
  display: flex;
  background-color: $color-NGLight3;
  align-items: center;
  height: 150px;
}

.inputContainer {
  position: static;
  flex: 1 2;
  margin: 0 22px 0 24px;
}

.inputCentreContainer {
  position: static;
  flex: 1 2;
  margin: 0 16px 0 24px;
}

.centreComboBox {
  flex: 2;
  width: 100%;
  height: 44px;
}

.datePickerContainer {
  flex: 2 1;
  margin-left: 22px;
  margin-right: 24px;
}

.label {
  font-size: 16px;
  line-height: 1.38;
  color: $color-NG-NeutralsGrey;
  font-weight: bold;
}

.labelContainer {
  margin: 0 16px 6px 2px;
}

.graphImage {
  width: 100%;
}

@media screen and (max-width: 1215px) {
  .filterContainer { 
    flex-direction: column;
  }
}