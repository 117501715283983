@import '../theme/colours';
@import '../theme/fonts';
@import '../theme/sizes';

.table {
  width: 100%;
  border-radius: 6px;
  background-color: $color-White;
  border-spacing: 0;
  text-align: left;

  tbody tr {
    border: solid 1px $color-NGLight3;
    height: 60px;

    &:nth-child(even){
      background-color: $color-NGLight3;
    }
  }
}

.pagination {
  text-align: right;
  padding: 16px;
}

.tableHeaderCell {
  color: $color-NG-NeutralsGrey;
  padding: 15px 16px;
  border-bottom: solid 2px $color-NGLight3;
  white-space: nowrap;
}

.tableCell {
  color: $color-NGDark1;
  padding: 10px 16px;
  border-bottom: solid 2px $color-NGLight3;
}

.actionsTableCell {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: inherit;
  box-sizing: border-box;
}

.actionsArea {
  text-align: right;
}

.pageHeaderRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: $color-NG-NeutralsGrey;
}

.getHelp {
  color: $color-Error;

  >*:first-child {
    font-size: 18px;
    vertical-align: top;
  }
}

.availabilityDate {
  color: $color-NG-NeutralsGrey;
}

.articlesFilter {
  display: flex;
}

.replacementFileWarning {
  color: $color-Error;
  margin-right: 5px;
  vertical-align: middle;
}

.newFileWarning {
  color: $color-Primary;
  margin-left: 5px;
  vertical-align: middle;
}
