@import '../theme/colours';
@import '../theme/sizes';

.appTitle {
  color: inherit; 
  text-decoration: none;
}

.surveyButton {
  margin-right: 100px;
}

.border{
  border: none;
  border-top: 1px solid $color-NGLight2;
  margin: 0;
  margin-top: 8px;
  padding-top: 5px;
  width: 100%;
  display: inline-block;
  &:focus {
    outline: none;
  }
}

.centerListTitle {
  color: $color-NG-NeutralsGrey;
  padding: 8px 6px 4px;
  display: inline-block;
  width: 100%;
  &:focus{
    outline: none;
  }
}

.userWidget {
  width: 274px;
  height: 59px;
  align-items: flex-start;
  padding: 3px 20px 3px 14px;
  &:active,
  &.activeWidget {
    background: transparent;
  }
}

.userWidgetLabel {
  text-align: left;
}

.userName {
  color: $color-NGDark1;
  display: inline-block;
  margin-bottom: 4px;
  font-family: -apple-system, BlinkMacSystemFont,
    sans-serif;
}

.centreName {
  color: $color-NG-NeutralsGrey;
  display: flex;
  align-items: center;
  height: 30px;
}

.userWidgetIcon {
  color: $color-NG-NeutralsGrey;
  position: absolute;
  right: 10px;
  top: 21px;
  width: 16px;
  height: 16px;
}

.dialog {
  width: 700px;
  border-radius: 8px;
}
  
.dialogHeader {
  font-size: 1.4rem;
}

.dialogContent { 
  color: #5c6066; 
  font-size: 1.1rem; 
  min-height: 120px;
  padding: 24px; 
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
  
.dialogActions {
  display: flex;
  justify-content: flex-end;
}
  
.cancelButton {
  margin-right: 16px;
} 

.userWidgetDropDown {
  max-height: calc(85vh - 70px);
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 5px;
}

.confirmationButton {
  width: 240px;
  display: inline-block;
  text-align: center;
}
