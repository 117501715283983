@import '../../theme/colours';

.root {
  display: block;
}

.list {
  display: block;
  padding: 0;
  margin: 0;
}

.item {
  display: inline;
}

.active {
  background-color: $color-NGLight2;
  color: $color-PrimaryDark1;
}

.ellipsis {
  color: $color-NGLight1;
  vertical-align: middle;
}
