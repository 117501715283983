@import '../../theme/colours';

.container {
  display: inline-block;
  position: relative;
}

.reportsFilterContainer {
  position: relative;
  display: inline-flex;
}

.popover {
  position: absolute;
  z-index: 1;
  margin: 2px 0;
  right: 0;
  &.down {
    top: 100%;
  }
  &.up { bottom: 100%; }
}

.menu {
  min-width: 100%;
  border-radius: 6px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px $color-NGLight2;
  background-color: $color-White;
  padding: 4px;
  list-style: none;
  box-sizing: border-box;
  text-align: left;
}

.hidden {
  display: none;
}
