@import '../../theme/colours';

.shrinkedFilter {
  width: 75%;
  display: flex;
  transition: 0.6s;
}

.table {
  transition: 0.6s;
  width: 100%;
  border-radius: 6px;
  background-color: $color-White;
  border-spacing: 0;
  text-align: left;

  tbody tr {
    border: solid 1px $color-NGLight3;
    height: 60px;

    &:nth-child(even){
      background-color: $color-NGLight3;
    }
    &:nth-child(odd){
      background-color: $color-White;
    }

    &.selectedRow {
      background-color: $color-PrimaryLight3;
    }
  }

  &.shrinkedTable {
    width: 75%;
    transition: 0.6s;
  }
}

.pagination {
  text-align: right;
  padding: 16px;
}

.tableHeaderCell {
  color: $color-NG-NeutralsGrey;
  padding: 15px 16px;
  border-bottom: solid 2px $color-NGLight3;
  white-space: nowrap;
}

.checkboxTableHeaderCell {
  color: $color-NG-NeutralsGrey;
  padding: 15px 0px 15px 16px;
  border-bottom: solid 2px $color-NGLight3;
  width: 15px;
}

.checkboxTableCell {
  color: $color-NGDark1;
  padding: 15px 0px 15px 16px;
  border-bottom: solid 2px $color-NGLight3;
  width: 15px;
}

.tableCell {
  color: $color-NGDark1;
  padding: 10px 16px;
  border-bottom: solid 2px $color-NGLight3;
}

.supervisorsFilter {
  transition: 0.6s;
  display: flex;
}

.supervisorsCell {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.hidden {
  display: none;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.tooltipContainer {
  display: inline;
  &:hover .tooltipWrapper {
    visibility: visible;
  }
}

.tooltipWrapper {
  position: absolute;
  visibility: hidden;
  height: 0;
}

.tooltip {
  display: block;
  position: relative;
  z-index: 2;
  top: 1.5em;
  left: 0;
  padding: 5px;
  background-color: $color-NGDark2;
  color: $color-White;
  text-align: center;
  border-radius: 6px;

  &::after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 15%;
    margin-left: -8px;
    border-width: 4px;
    border-style: solid;
    border-color:  transparent transparent $color-NGDark2 transparent;
  }
}

.userName {
  font-family: sans-serif;
  font-size: 15px;
}

.highlightedTooltip {
  background-color: $color-PrimaryLight2;
  border-radius: 6px;
}