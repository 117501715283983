@import "../../../theme/colours";

.decor {
  color: $color-Primary;
  padding: 10px;
  font-size: 10px;
  .icon {
    display: block;
    font-size: 20px;
  }
  margin-right: 6px;
}

.empty {
  margin: 28px 4px;
  text-align: center;
  color: $color-NG-NeutralsGrey;
}

.inputBox {
  width: 100%;
  display: inline-flex;
  background: $color-White;
  border-top: solid 1px $color-NGLight2;
  border-bottom: solid 1px $color-NGLight2;
  padding: 4px 0;
  transition: all 0.3s ease;
  &.focused {
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.05);
    border-color: $color-NGLight1;
  }
  align-items: center;
}

.input {
  width: 100%;
  border: 0;
  margin: 0;
  padding: 6px 0 7px;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  letter-spacing: inherit;
  -webkit-tap-highlight-color: transparent;
  &:focus {
    outline: none;
  }
}

.inputLabel {
  white-space: nowrap;
  display: inline-block;
  width: 0;
  position: relative;
  color: $color-Primary;
  &.focused {
    left: -999em;
  }
}

.screenReader {
  clip: rect(1px, 1px, 1px, 1px);
  font-size: 14px;
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.clear {
  margin: 7px;
  color: $color-NGDark1;

  &[data-item="menuitem"] {
    width: inherit;

    &:focus {
      outline: solid 2px orange;
      background-color: transparent;
      color: $color-NGDark1;
    }
    
    &:hover {
      background: $color-NGLight2;
      color: $color-NGDark1;
    }

    &:active {
      background: $color-PrimaryLight2;
    }
  }
}

.hidden {
  display: none;
}
