@import '../../theme/colours';

.messageStyle {
  height: auto;
  color: $color-NGDark1;
  background-color: $color-White;
  display: flex;
  flex-direction: column;
  padding: 12px 10px 12px 34px;
  box-sizing: border-box;
  position: relative;

  &.activeMessage {
    background-color: $color-NGLight3;
  }

  &.readMessage {
    color: $color-NG-NeutralsGrey;
  }

  &:focus {
    outline: auto 2px orange;
  }

  &:hover {
    background-color: $color-NGLight3;
    cursor: pointer;
  }
}

.messageIcon {
  position: absolute;
  left: 12px;
  top: 15px;

  & svg {
    display: block;
    width: 10px;
    height: 10px;
  }
}

.messageTitle {
  position: relative;
  padding-right: 40px;
}

.messageTime {
  position: absolute;
  right: 0;
  color: $color-NG-NeutralsGrey;
}

.messageContent {
  height: 0;
  overflow: hidden;
  opacity: 0;
  transform: translateY(-20%);
  transition: transform 0.2s ease-in;

  &.activeContent {
    height: auto;
    margin-top: 8px;
    opacity: 1;
    transform: translateY(0);
  }
}

.messageLink {
  margin-top: 12px;
}
