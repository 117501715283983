@import '../../theme/colours';
@import '../../theme/fonts';

.mainWrapper {
  display: flex;
  overflow: hidden;
}

.columnLeft {
  width: 60%;
  font-family: $font-family-source-sans-pro;
  background-color: $color-White;
  height: 800px;
  padding: 10px 10px;
  margin-bottom: 20px;
  position: relative;
  border-radius: 6px;
  border: solid 1px $color-NGLight2;
  color: rgba(0, 0, 0, 0.87);
  overflow-wrap: anywhere;
}

.columnLeftWithTabs {
  @extend .columnLeft;
  padding: 0 10px 10px 10px;
}

.downloadButtonContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 7%;
}

.columnRight {
  width: 40%;
  height: 650px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-left: 70px;
}

.graphImageContainer {
  margin-top: 50px;
  max-height: 300px;
  overflow: hidden;
}

@media screen and (max-width: 800px) {
  .mainWrapper { 
    flex-direction: column-reverse;
    margin-top: 80px;
  }
  
  .columnRight {
    width: 100%;
    height: 10vh;
    margin-bottom: 120px;
    justify-content: center;
    align-items: center;
  }

  .columnLeft {
    width: 85%;
  }

  .messageContainer {
    width: 80%;
  }
}

