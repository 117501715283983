@import '../../theme/colours';

.container {
  width: 400px;
  display: flex;
  flex-direction: column;
  margin: 80px 32px;
  position: fixed;
  overflow-y: hidden;
  overflow-x: hidden;
  top: 0;
  right: 0;
  box-shadow: 0px 0px 0px -1px rgba(0, 0, 0, 0.2), 0px 2px 3px -1px rgba(0, 0, 0, 0.14), 0px -1px 4px -1px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #fff;
  z-index: 1302;
  padding-right: 25px;
}

.iconColor {
  color: $color-NGDark2;
}

.closeButton {
  position: absolute;
  right: 8px;
  top: 8px;

  &:hover svg,
  &:hover {
    color: $color-PrimaryDark1;
  }
}

.content {
  flex: 1 1 auto;
  padding: 0px 25px 16px 1px;
  overflow-y: auto;
  color: $color-NG-NeutralsGrey;
  -webkit-overflow-scrolling: touch;
  white-space: pre-wrap;
}

.title {
  margin: 0;
  padding: 16px 0px 4px 1px;
}

.columnLeft {
  float: left;
  width: 15%;
}

.columnRight {
  float: left;
  width: 85%;
}

.row ::after {
  display: table;
  clear: both;
}
